.typeahead-standalone {
    position: relative;
    text-align: left;
    color: #000;

    .tt-input {
        z-index: 1;
        background: transparent;
        position: relative;
    }
    .tt-hint {
        position: absolute;
        left: 0;
        cursor: default;
        user-select: none;
        background: #fff;
        color: silver;
        z-index: 0;
    }
    .tt-list {
        background: #fff;
        z-index: 1000;
        box-sizing: border-box;
        overflow: auto;
        border: 1px solid rgba(50, 50, 50, 0.6);

        &.tt-hide {
            display: none;
        }

        // targets all templates
        div[class^="tt-"] {
            padding: 0 4px;
        }

        .tt-suggestion {
            &:hover,
            &.tt-selected {
                background: #55acee;
                cursor: pointer;
            }
            .tt-highlight {
                font-weight: 900;
            }
        }

        .tt-group {
            background: #eee;
        }
    }
}